<template>
  <div class="user-list">
    <KTCodePreview v-bind:title="'Danh sách tài khoản'">
      <template v-slot:toolbar>
        <b-row>
          <router-link
            to="/workforce-management/upsert-user"
            v-show="checkPermission('USER_INSERT')"
          >
            <b-button variant="primary" size="sm" class="font-weight-bolder">
              <i class="fa-solid fa-plus" style="font-size: 1rem"></i>
              Tạo mới
            </b-button>
          </router-link>
        </b-row>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Tìm kiếm theo tên đăng nhập"
              v-model.trim="filterParams.userName"
              single-line
              v-on:keyup.enter="fetchMainData"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              size="sm"
              single-line
              placeholder="Tìm kiếm theo tên nhân viên"
              v-model.trim="filterParams.fullName"
              v-on:keyup.enter="fetchMainData"
            ></b-input>
          </b-col>
          <b-col>
            <Autosuggest
              :suggestions="optionsRole"
              :model="filterParams.roleName"
              :placeholder="'quyền'"
              :limit="10"
              @change="onChange($event, 'role')"
              @select="onSelected($event, 'role')"
              errorMessages="Yêu cầu chọn quyền."
              suggestionName="name"
            />
          </b-col>
          <b-col cols="1">
            <b-button
              style="width: 70px"
              class="font-weight-bolder"
              variant="primary"
              size="sm"
              @click="fetchMainData()"
              >Lọc
            </b-button>
          </b-col>
        </b-row>
        <b-table
          :items="items"
          :hover="true"
          :fields="fields"
          :busy="onLoading"
          class="table-bordered table-hover col-md-12"
          ref="tbl-users"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="7"></vcl-table>
          </template>
          <template #cell(stt)="row">
            <div>
              <span
                v-text="row.index + 1"
                class="d-flex justify-content-center font-weight-bolder"
              ></span>
            </div>
          </template>
          <template v-slot:cell(code)="row">
              <span>
                {{ row.item.code }}
              </span>
          </template>
          <template #cell(username)="row">
            <div class="font-weight-bolder">
              <div
                @mouseover="handleCellMouseover('username', row.index)"
                @mouseout="handleCellMouseout('username', row.index)"
              >
                <span class="mr-1" v-text="row.item.userName"></span>
                <i
                  title="Copy tài khoản"
                  v-clipboard:copy="row.item.userName"
                  class="fa-regular fa-copy cursor-pointer d-none"
                  style="font-size: 1rem"
                  :ref="`cell-username-${row.index}`"
                  @click="
                    makeToastSuccess(`Đã copy tài khoản ${row.item.userName}`)
                  "
                ></i>
              </div>
            </div>
          </template>
          <template #cell(email)="row">
            <span
              class="d-block"
              v-if="row.item.email"
              @mouseover="handleCellMouseover('email', row.index)"
              @mouseout="handleCellMouseout('email', row.index)"
            >
              <span class="mr-1" v-text="row.item.email"></span>
              <i
                title="Copy email"
                v-clipboard:copy="row.item.email"
                class="fa-regular fa-copy cursor-pointer d-none"
                style="font-size: 1rem"
                :ref="`cell-email-${row.index}`"
                @click="makeToastSuccess(`Đã copy email ${row.item.email}`)"
              ></i>
            </span>
          </template>
          <template #cell(fullName)="row">
            <div
              @mouseover="handleCellMouseover('fullName', row.index)"
              @mouseout="handleCellMouseout('fullName', row.index)"
            >
              <span class="mr-1" v-text="row.item.fullName"></span>
              <span>
                <i
                  title="Copy nhân viên"
                  v-clipboard:copy="row.item.fullName"
                  class="fa-regular fa-copy cursor-pointer d-none"
                  style="font-size: 1rem"
                  :ref="`cell-fullName-${row.index}`"
                  @click="
                    makeToastSuccess(
                      `Đã copy nhân viên ${row.item.fullName}`,
                    )
                  "
                ></i>
              </span>
            </div>
          </template>
          <template #cell(isActive)="row">
              <!-- <span
                v-if="checkStatus(row.item)"
                v-text="row.item.status"
                class="label font-weight-bold label-lg label-light-success label-inline"
              ></span>
              <span
                v-else
                v-text="row.item.status"
                class="label font-weight-bold label-lg label-light-danger label-inline"
              ></span> -->
              <i
              v-bind:class="{
                'fas fa-check text-success': row.item.isActive == 1,
                'fas fa-minus-circle text-danger': row.item.isActive != 1,
              }"
            />
          </template>
          <template #cell(storeName)="row">
            <div class="text-center" style="width: max-content">
              <span
                v-text="row.item.storeName"
                class="font-weight-bolder"
              ></span>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="showRestorePasswordAlert(row.item)">
                  <span style="font-size: 12px">
                    <i style="font-size: 10px" class="fas fa-redo-alt"></i>
                    &nbsp; Reset mật khẩu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="font-size: 12px">
                    <i style="font-size: 10px" class="flaticon2-box-1"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('USER_DELETE')"
                >
                  <span
                    class="text-danger"
                    style="font-size: 12px"
                    v-if="isDisplayItem(row.item, 'user.open')"
                  >
                    <i
                      class="fa-solid fa-lock"
                      style="font-size: 1rem; color: #de3333"
                    ></i>
                    &nbsp; Khoá tài khoản
                  </span>
                  <span
                    class="text-primary"
                    style="font-size: 12px"
                    v-else-if="isDisplayItem(row.item, 'user.lock')"
                  >
                    <i
                      class="fa-solid fa-lock-open"
                      style="font-size: 1rem; color: #3699ff"
                    ></i>
                    &nbsp; Mở tài khoản
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số tài khoản:
              {{ total }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              pills
              v-show="numberOfPage >= 2"
              :number-of-pages="numberOfPage"
              :link-gen="linkGen"
              align="right"
              v-model="currentPage"
              use-router
              @change="fetchMainData"
              class="mt-3"
              style="border-radius: 5px"
            ></b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import localData from '@/utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import {
  makeToastSuccess,
  makeToastFaile,
  removeAccents,
  cloneDeep,
} from '@/utils/common';
import fields from './fields/tblUserFields.json';
// import optionsLoginFailed from './fields/optLoginFailed.json';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

const ISACTIVE = {
  AVAIBLE: 1,
  LOCK: 2,
};
export default {
  data() {
    return {
      fields: fields,
      items: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      searchUsername: '',
      page: 1,
      isActive: 1,
      numberOfPage: 1,
      total: '',
      onLoading: false,
      count: 1,
      searchEmployee: '',
      // optionsLoginFailed: optionsLoginFailed,
      orderLoginFailed: null,
      filterParams: {
        userName: null,
        fullName: null,
        roleName: null,
        roleId: null,
      },
      optionsRole: [],
      optionOrigin: {
        optionsRole: [],
      },
      currentPage: 1,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
  },
  created() {
    this.fetchMainData();
    this.fetchRoles();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Tài khoản', route: 'users' },
      { title: 'Danh sách tài khoản' },
    ]);
  },
  methods: {
    makeToastSuccess,
    onChange(textInput = '', type) {
      switch (type) {
        case 'role': {
          this.filterParams.roleId = null;
          this.optionsRole = this.fitlerOptionsBy(
            this.optionOrigin.optionsRole,
            textInput,
            'name',
            10,
          );
          break;
        }
      }
    },
    onSelected(option, type) {
      switch (type) {
        case 'role': {
          this.filterParams.roleId = option.item ? option.item.id : null;
          this.filterParams.roleName = option.item ? option.item.name : null;
          break;
        }
      }
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    getTextLogin(actionMode) {
      switch (actionMode) {
        case 1:
          return 'Yêu cầu đổi mật khẩu lần đầu';
        case 2:
          return 'Yêu cầu đăng nhập lại';
        case 3:
          return 'Yêu cầu đăng nhập lại và đổi mật khẩu';
        default:
          break;
      }
    },
    isDisplayItem(item, name) {
      switch (name) {
        case 'user.lock':
          return [0, 2].includes(item.isActive);
        case 'user.open':
          return [1].includes(item.isActive);
      }
    },
    handleCellMouseover(type, rowIndex) {
      this.$refs[`cell-${type}-${rowIndex}`].classList.remove('d-none');
    },
    handleCellMouseout(type, rowIndex) {
      this.$refs[`cell-${type}-${rowIndex}`].classList.add('d-none');
    },
    fetchMainData: async function () {
      this.onLoading = true;
      const LIMIT = 10;
      const params = {
        page: this.$route.query.page || 1,
        limit: LIMIT,
        ...this.filterParams,
        // orderBy: this.filterParams.orderLoginFailed
        //   ? ['failedLoginCount', this.filterParams.orderLoginFailed]
        //   : null,
      };
      ApiService.query('users/list', {
        params: params,
      }).then(({ data }) => {
        this.items = [];
        this.numberOfPage = data.data.paginations.totalPages || 1;
        this.total = data.data.paginations.total;

        // const statusName = {
        //   1: 'Hoạt động',
        //   2: 'Đang khoá',
        // };
        data.data.data.forEach((element) => {

          this.items.push({
            ...element,
            storeName:  element.store?.name ?? '',
            jobTitleConfiguration:element.jobTitleConfigurations?.map(config => config.name).join(', ') || '',
            roleName: element.roles?.map(role => role.name).join(', ') || '',
            // status: statusName[element.isActive],
          });
          this.count++;
        });
        // console.log("items [0]", this.items[0]);
        
        this.onLoading = false;
      });
    },
    editItem: function (item) {
      this.$router.push({
        path: 'workforce-management/update-user',
        query: { id: item.id },
      });
    },
    async changeActiveUser(user) {
      ApiService.post(`users/active/${user.id}`).then(({ data }) => {
        if (data.status !== 1) {
          return makeToastFaile(data.message);
        }
        makeToastSuccess(data.message);
        this.fetchMainData();
      });
    },
    showDeleteAlert(item) {
      const isActive = item.isActive;
      const title = isActive === ISACTIVE.AVAIBLE ? 'Khoá' : 'Mở';

      Swal.fire({
        title: `${title} tài khoản!`,
        text: `Bạn có chắc muốn ${title} tài khoản ${item.username} này không ?`,
        icon: isActive ? 'question' : 'warning',
        showCancelButton: true,
        confirmButtonColor: isActive ? '#3699FF' : 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: title,
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (!result.value) return;
        this.changeActiveUser(item);
      });
    },
    restorePassword: async function (item) {
      const id = item.id;
      if (!id) {
        makeToastFaile('Yêu cầu id');
        return;
      }
      ApiService.post(`users/password/restore/${id}`, {
        // sentMail,
        // email: item.email,
      }).then(({ data }) => {
        if (data.status === 1) {
          makeToastSuccess(data.message);
          this.fetchMainData();
        } else {
          makeToastFaile(data.message);
        }
      });
    },
    showRestorePasswordAlert: function (item) {
      Swal.fire({
        title: 'Khôi phục mật khẩu!',
        html: `<span>Bạn có chắc muốn khôi phục mật khẩu cho tài khoản này không ?</span>
                <p style="color: #3085d6">Mật khẩu sẽ khôi phục thành mặc định</p>`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Trở về</span>',
        reverseButtons: true,
        // input: 'checkbox',
        // inputPlaceholder: 'Gửi mail thông báo',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (!result.isConfirmed) return;
        // if (result.value && !item.email) {
        //   Swal.fire({
        //     icon: 'error',
        //     text: 'Vui lòng cung cấp địa chỉ email',
        //   });
        //   return;
        // }
        this.restorePassword(item);
      });
    },
    checkPermission(condition) {
      return localData.checkPermission(condition);
    },
    checkStatus(item) {
      return item.status === 'Hoạt động';
    },
    checkViewOnly() {
      let count = 0;
      if (localData.checkPermission('USER_VIEW')) {
        count++;
      }
      if (localData.checkPermission('USER_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    fetchRoles: async function () {
      this.optionsRole = [];
      ApiService.query('role', {
        params: {
          page: 1,
          limit: 1000,
        },
      }).then((response) => {
        const { data } = response.data;
        const roles = data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });

        this.optionOrigin.optionsRole = cloneDeep(roles);
        this.optionsRole = roles;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-list ::v-deep {
  .table th,
  .table td {
    vertical-align: middle;
  }
  .table {
    table-layout: fixed;
    word-wrap: break-word;
  }
}
.text-center-custom {
  text-align: center !important;
  vertical-align: middle !important;
}

</style>
